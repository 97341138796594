import entities from "@/core/entities/index.js";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const adminEntities = [
  "business_report", "course_Summary", "programme_period_summary", 
  "tool_Summary", "toolkit_Summary", "user_profile_summary", 
  "user_Summary", "tootkit_Summary"
];

const adminExtendedEntities = [
  { to: "/reports_generator", icon: "mdi-label", text: "Report Generation" },
  { to: "/message_analytics", icon: "mdi-label", text: "Message Analytics" }
];

const adminWithLimitedAccessEntities = ["academy_user", "course_period_user_summary"];

const getSideBarItems = async (isAdmin, roleMask) => {
  const regEx = /_/g;
  const adminWithLimitedAccess = !isAdmin && roleMask === 1024;

  if (isAdmin) {
    adminEntities.forEach((adminEntity) => {
      if (entities[adminEntity]) {
        entities[adminEntity].sidebar = true;
      }
    });
  } else if (adminWithLimitedAccess) {
    Object.keys(entities).forEach((entityKey) => {
      entities[entityKey].sidebar = false;
    });
    adminWithLimitedAccessEntities.forEach((entity) => {
      if (entities[entity]) {
        entities[entity].sidebar = true;
      }
    });
  }

  const sidebarItems = Object.keys(entities)
    .filter((entity) => entities[entity].sidebar)
    .map((entity) => ({
      _name: "CSidebarNavItem",
      text: capitalize(entity.replace(regEx, " ")),
      to: `/${entity}`,
      icon: "mdi-label",
      exact: false
    }));

  const sidebarContent = [
    { to: "/dashboard", icon: "mdi-label", text: "Dashboard" },
    ...(isAdmin ? adminExtendedEntities : []),
    ...sidebarItems
  ];

  return sidebarContent;
};

export default getSideBarItems;
